* {
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    height: 100%;
    width: 100%;
}

body {
    font-family: neuzeit-grotesk, sans-serif;
    font-size: 1.6rem;
    line-height: 3rem;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    color: #8a95a3;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4 {
    font-family: neuzeit-grotesk, sans-serif;
}
h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0;
    padding: 0 0 1.5rem 0;
    font-weight: 700;
    color: #053b80;
}
h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    margin: 0;
    padding: 0 0 1.5rem 0;
    font-weight: 700;
    color: #2a323c;
}
h3 {
    font-size: 2.1rem;
    line-height: 2.6rem;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #2a323c;
}
h4 {
    font-size: 1.5rem;
    line-height: 1.9rem;
    margin: 0;
    font-weight: 700;
    color: #2a323c;
}
